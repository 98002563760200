import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql, Link } from "gatsby"
import styled, {keyframes} from 'styled-components'
import {rgba} from 'polished'

import theme from '../styles/theme'
import {media} from '../styles/utils'

const HeroWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(70vh - 110px);
  width: 100%;

  ${media.medium`
    grid-template-columns: 1fr;
  `}
`;

const HeroTextWrap = styled.div`
  background-color: ${props => props.theme.color.lightGrey};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  position: relative;
  
  
  > h1 {
    color: ${props => props.theme.color.textMain};
    padding: 40px;
    padding: 0 10px;
    font-size: 40px;
    line-height: 50px;
    z-index: 2;
    text-align: center;
    width: 100%;
  }
`;

const FadedBGImage = styled(Img)`
  width: 100%;
  height: 100%;
  /* background-image: url(${props => props.img}); */
  background-size: cover;
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: .25;
`;


const BGImageWrap = styled.div`
  position: relative;
`;

const BGImage = styled(Img)`
  width: 100%;
  height: 100%;
  /* background-image: url(${props => props.img}); */
  background-size: cover;
  position: absolute !important;
  top: 0;
  right: 0;
`;

const HeroH1 = styled.h1`
  width: 100%;
  text-align: center;
  color: ${props => props.theme.color.textMain};
  font-size: 50px;
	line-height: 60px;

  > div p {
    color: ${props => props.theme.color.textMain} !important;
    /* text-transform: uppercase; */
    font-size: 50px;
	  line-height: 60px;
  }
`

const HeroLink = styled(Link)`
  margin-top: 30px;
`;

const HeroProject = (props) => {
  const {name, coverImage} = props.data.contentfulProject
  
  return (
    <HeroWrap>
      <HeroTextWrap>
        <HeroH1>{name}</HeroH1>
      </HeroTextWrap>
      <BGImageWrap>
        <BGImage fluid={coverImage.fluid}  />
      </BGImageWrap>
    </HeroWrap>
  )}
export default HeroProject
