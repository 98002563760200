import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import styled from 'styled-components'

import config from '../utils/siteConfig'
import theme from '../styles/theme'
import Layout from '../components/Layout'
import HeroProject from '../components/HeroProject'
import SimpleHero from '../components/SimpleHero'
import SEO from '../components/SEO'
import PostLinks from '../components/PostLinks'
import { media } from '../styles/utils'

const ProjectImage = styled.div`
  /* margin: 30px 0 0 0; */
`

const HeroWrap = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  height: calc(70vh - 110px);
  width: 100%;

  ${media.medium`
    height: auto;
    grid-template-columns: 1fr;
  `}
`

const HeroTextWrap = styled.div`
  background-color: ${(props) => props.theme.color.lightGrey};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  position: relative;

  ${media.medium`
    height: auto;
    min-height: 50vh;
  `}

  > h1 {
  }
`

const FadedBGImage = styled(Img)`
  width: 100%;
  height: 100%;
  /* background-image: url(${(props) => props.img}); */
  background-size: cover;
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0.25;
`

const BGImageWrap = styled.div`
  position: relative;

  ${media.medium`
    height: auto;
    min-height: 50vh;
  `}
`

const BGImage = styled(Img)`
  width: 100%;
  height: 100%;
  /* background-image: url(${(props) => props.img}); */
  background-size: cover;
  position: absolute !important;
  top: 0;
  right: 0;
`

const HeroH1 = styled.h1`
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.color.textMain};
  padding: 40px;
  padding: 0 10px;
  font-size: 40px;
  line-height: 60px;
  z-index: 2;

  > div p {
    color: ${(props) => props.theme.color.textMain} !important;
    /* text-transform: uppercase; */
    font-size: 50px;
    line-height: 60px;
  }
`

const HeroLink = styled(Link)`
  margin-top: 30px;
`

const ButtonWrap = styled.div`
  display: flex;
`

const ProjectTemplate = ({ data, pageContext }) => {
  const {
    name,
    slug,
    content,
    completedDate,
    category,
    coverImage,
    images,
    elementsOfDesign,
    services,
  } = data.contentfulProject
  const postNode = data.contentfulProject
  const previous = pageContext.prev
  const next = pageContext.next
  const seo = {
    pageTitle: name,
    shareImage: {
      ogimg: {
        src: coverImage.ogimg.src,
        width: coverImage.ogimg.width,
        height: coverImage.ogimg.height,
      },
    },
    metaDescription: {
      metaDescription: content.childMarkdownRemark.excerpt,
    },
    metaKeywords: {
      metaKeywords: `KPC Architectural Products, Inc., ${name}`,
    },
  }

  return (
    <Layout>
      <Helmet>
        <title>{`${name} - ${config.siteTitle}`}</title>
      </Helmet>
      {seo && (
        <SEO pagePath={`project/${pageContext.slug}`} postNode={seo} pageSEO />
      )}
      {/* <HeroProject data={data} /> */}
      {/* <SimpleHero headline={name} secondaryURL="/projects/" secondaryName="Projects" img={coverImage.fluid} pagePath="projects" previous={previous} next={next} /> */}

      <HeroWrap>
        <HeroTextWrap>
          <HeroH1>{name}</HeroH1>
          <ButtonWrap>
            {previous && (
              <HeroLink
                className="btn theme-btn"
                to={`/projects/${previous.slug}/`}
              >
                &#8592; Previous
              </HeroLink>
            )}
            {next && (
              <HeroLink
                className="btn theme-btn"
                to={`/projects/${next.slug}/`}
              >
                Next &#8594;
              </HeroLink>
            )}
          </ButtonWrap>
        </HeroTextWrap>
        <BGImageWrap>
          <BGImage fluid={coverImage.fluid} />
        </BGImageWrap>
      </HeroWrap>

      <div className="portfolio-details style1 white-bg pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-lg-6">
              <div className="project-sidebar">
                <div
                  className="project-details mb-35"
                  dangerouslySetInnerHTML={{
                    __html: content.childMarkdownRemark.html,
                  }}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-6">
              <div className="project-sidebar pl-40">
                <div className="project-info">
                  <ul>
                    <li>
                      <h5>Client :</h5>
                      <p>{name}</p>
                    </li>
                    <li>
                      <h5>Complete Date:</h5>
                      <p>{completedDate}</p>
                    </li>
                    {elementsOfDesign && (
                      <li>
                        <h5>Elements of Design:</h5>
                        <p>
                          {elementsOfDesign.map((element, i) => {
                            return (
                              <span key={element + i}>
                                {element}
                                {elementsOfDesign.length === i + 1 ? (
                                  ''
                                ) : (
                                  <>&nbsp;|&nbsp;</>
                                )}
                              </span>
                            )
                          })}
                        </p>
                      </li>
                    )}
                    {services && (
                      <li>
                        <h5>Services:</h5>
                        <p>
                          {services.map((service, i) => {
                            return (
                              <span key={service + i}>
                                {service}
                                {services.length === i + 1 ? (
                                  ''
                                ) : (
                                  <>&nbsp;|&nbsp;</>
                                )}
                              </span>
                            )
                          })}
                        </p>
                      </li>
                    )}
                    {category && (
                      <li>
                        <h5>Categor{category.length > 1 ? 'ies' : 'y'}:</h5>
                        <p>
                          {category.map((cat, i) => {
                            return (
                              <span key={cat.id}>
                                <Link to={`/projects/category/${cat.slug}`}>
                                  {cat.title}
                                </Link>
                                {category.length === i + 1 ? (
                                  ''
                                ) : (
                                  <>&nbsp;|&nbsp;</>
                                )}
                              </span>
                            )
                          })}
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="blog-area style2 white-bg mb-60 clearfix">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-md-12 col-text-center mobi-mb-50">
              <div className="project-images">
                {images &&
                  images.map((image) => (
                    <ProjectImage key={image.id} className="project-image">
                      <Img fluid={image.fluid} />
                    </ProjectImage>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PostLinks
        pagePath="projects"
        previous={previous}
        next={next}
        isProject
      />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      id
      name
      slug
      content {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 80)
        }
      }
      completedDate(formatString: "MMMM DD, YYYY")
      category {
        title
        slug
        id
      }
      coverImage {
        title
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_noBase64
        }
        ogimg: resize(width: 1200) {
          src
          width
          height
        }
      }
      images {
        id
        title
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_noBase64
        }
        file {
          url
        }
      }
      elementsOfDesign
      services
    }
  }
`

export default ProjectTemplate
